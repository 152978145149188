






































































































































































































































































































































































































































































































































































@mixin drawImg($left, $top) {
  left: $left;
  top: $top;
  z-index: 99;
}
.sharing_wrapper {
  width: fit-content;
  min-width: 150px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}
.while_printing_the_report {
  background: #fff;
  padding: 20px;
  // changes
  // direction: ltr;
  // &.reverse_dir {
  //   direction: rtl;
  // }
}
.is_old_wrapper {
  .main_images_checks_container {
    width: 800px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background: #fff;
    margin: 30px auto;
    flex-direction: row-reverse;
    justify-content: center;
    .wrapper_of_images {
      display: flex;
      gap: 15px;
      margin-bottom: 10px;
      // start container for images
      .main_main {
        .wrapper_two {
          width: 200px;
          height: 200px;
          background: #fff;
          margin: auto;
          display: flex;
          flex-wrap: wrap;
          .item {
            display: block;
            width: 40px;
            height: 40px;
          }
          // }
        }
        .text_images {
          display: flex;
          justify-content: center;
          gap: 6px;
        }
      }
      // end container for images
      .is_items_with_checkbox {
        .is_is_checker {
          display: flex;
          justify-content: start;
          gap: 8px;
          flex-direction: row-reverse;
        }
      }
    }

    // Start When English - Direction == Ltr
    &.with_english_direction {
      //direction: rtl; // here the problem
      .main_main {
        .wrapper_two {
          width: 200px;
          background: #ddd;
          height: 200px;
          // margin: auto;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          .item {
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;

            &.custom_img_en_class_1 {
              @include drawImg(0, 0);
            }
            &.custom_img_en_class_2 {
              @include drawImg(40px, 0);
            }
            &.custom_img_en_class_3 {
              @include drawImg(80px, 0);
            }
            &.custom_img_en_class_4 {
              @include drawImg(120px, 0);
            }
            &.custom_img_en_class_5 {
              @include drawImg(160px, 0);
            }
            &.custom_img_en_class_6 {
              @include drawImg(0, 40px);
            }
            &.custom_img_en_class_7 {
              @include drawImg(40px, 40px);
            }
            &.custom_img_en_class_8 {
              @include drawImg(80px, 40px);
            }
            &.custom_img_en_class_9 {
              @include drawImg(120px, 40px);
            }
            &.custom_img_en_class_10 {
              @include drawImg(160px, 40px);
            }
            &.custom_img_en_class_11 {
              @include drawImg(0, 80px);
            }
            &.custom_img_en_class_12 {
              @include drawImg(40px, 80px);
            }
            &.custom_img_en_class_13 {
              @include drawImg(80px, 80px);
            }
            &.custom_img_en_class_14 {
              @include drawImg(120px, 80px);
            }
            &.custom_img_en_class_15 {
              @include drawImg(160px, 80px);
            }
            &.custom_img_en_class_16 {
              @include drawImg(0, 120px);
            }
            &.custom_img_en_class_17 {
              @include drawImg(40px, 120px);
            }
            &.custom_img_en_class_18 {
              @include drawImg(80px, 120px);
            }
            &.custom_img_en_class_19 {
              @include drawImg(120px, 120px);
            }
            &.custom_img_en_class_20 {
              @include drawImg(160px, 120px);
            }
            &.custom_img_en_class_21 {
              @include drawImg(0, 160px);
            }
            &.custom_img_en_class_22 {
              @include drawImg(40px, 160px);
            }
            &.custom_img_en_class_23 {
              @include drawImg(80px, 160px);
            }
            &.custom_img_en_class_24 {
              @include drawImg(120px, 160px);
            }
            &.custom_img_en_class_25 {
              @include drawImg(160px, 160px);
            }
          }
          // }
        }
      }
    }
  }
}

.is_items_with_checkbox {
  .is_is_checker {
    display: flex;
    // justify-content: start;
    justify-content: end;
    gap: 8px;
    // flex-direction: row-reverse;
    // margin-bottom: 5px;
  }
}

.information {
  display: flex;
  background: #fff;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 30px;
  .part_one {
    display: flex;
    flex-wrap: wrap;
    .parts {
      display: flex;
      width: 50%;
      margin-bottom: 15px;
      &.spans {
        // display: flex;
        gap: 20px;
        .is_span {
          display: flex;
          gap: 5px;
          .border_span {
            width: 25px;
            height: 25px;
            border: 1px solid #000;
            &.pre {
              background: #87cb16;
              border-color: #87cb16;
            }
            &.post {
              background: #87cb16;
              border-color: #87cb16;
            }
          }
        }
      }
      .when_name {
        margin: 0 5px;
        color: #555;
      }
    }
  }
  .part_two {
    .every {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-bottom: 8px;
      .is_name {
        min-width: 100px;
      }
      .item_border {
        border: 1px solid #000;
        width: 40px;
        height: 37px;
        &.date {
          width: 120px;
        }
      }
    }
  }
}

.footer_buttons_wrapper {
  display: flex;
  justify-content: space-between;
  background: #f7f7f8;
  .next_previous_btn {
    min-width: 120px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffa534;
    border: 1px solid #ffa534;
    color: #fff;
    border-radius: 8px;
    transition: 0.3s;
    &.not_active {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  .while_printing_the_report {
    height: 100%;
    width: 100%;
    page-break-after: always !important;
  }
  .for_printing_buttons {
    display: none;
  }
  .wrapper_for_printing:last-child {
    page-break-after: avoid;
    page-break-inside: avoid;
    margin-bottom: 0px;
  }
  .while_printing_the_report:last-child {
    page-break-after: avoid;
    page-break-inside: avoid;
    margin-bottom: 0px;
  }
}
::v-deep .el-checkbox {
  margin-bottom: 0;
}

.delete_answer_img {
  background: #eee;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  .icon_answer_img {
    color: #fb404b;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    background-color: #fb404b;
    .icon_answer_img {
      color: #fff;
    }
  }
}
